import { useState, useEffect } from "react";

export const useIntersect = (
  RefElement: React.RefObject<HTMLElement>,
  threshold: number[] = [0, 1],
  rootMargin = "-1px 0px 0px 0px"
) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const cachedRef = RefElement.current,
      observer = new IntersectionObserver(([e]) => setIsSticky(e.intersectionRatio === 0), {
        threshold,
        rootMargin
      });

    cachedRef && observer.observe(cachedRef);

    // unmount
    return function () {
      cachedRef && observer.unobserve(cachedRef);
    };
  }, [RefElement]);

  return isSticky;
};

export const AddToBag = () => {
  const [bagItems, setBagItems] = useState([]);
  return {
    bagItems,
    setBagItems
  };
};
