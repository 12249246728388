import React from "react";
import Layout from "../../components/Layout";
import { Container } from "react-bootstrap";
import SEO from "../../components/Seo";
import HeroBanner from "../../components/HeroBanner";
import { graphql, PageProps } from "gatsby";
import {
  ImageInterface,
  LandingSectionsInterface,
  LocalizedContextInterface,
  PortableText
} from "../../types/SanityTypes";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import StructuredData from "../../components/StructuredData";
import { PageModalInterface, Seo } from "../../components/PageModal";
import { LocalisedPageContext } from "../../types/PageTypes";
import ProductCategorySearch from "../../components/ProductCategorySearch";
import { AddToBag } from "../../utils/hooks";
import BreadCrumbs from "../../components/BreadCrumbs";

export type CategoryPageQueryProps = {
  categoryPage: {
    seo: Seo;
    name: string;
    slug: {
      current: string;
    };
    paImage?: ImageInterface;
    categoryId: string;
    _rawIntroduction: PortableText;
    pageModal?: PageModalInterface;
    landingSections: LandingSectionsInterface[];
    // For Preview
    introduction?: PortableText;
    title?: string;
    description?: string;
    paDescription?: string;
    parentCategory?: {
      name: string;
      slug: {
        current: string;
      };
    };
  };
} & LocalizedContextInterface;

type CategoryPageContext = LocalisedPageContext;

export const data = graphql`
  query categoryPage($_id: String, $language: String) {
    categoryPage: sanityCategory(_id: { eq: $_id }) {
      ...ProductCategoryPageFieldsFull
    }
    ...LocalizedContext
  }
`;

const ProductCategoryPage = (props: PageProps<CategoryPageQueryProps, CategoryPageContext>) => {
  const { htmlLang } = useSiteMetadata();
  const ProductCategoryPage = props.data.categoryPage;

  const pageTitle = ProductCategoryPage.seo?.metaTitle || ProductCategoryPage.title;
  const pageDescription = ProductCategoryPage.paDescription || ProductCategoryPage.description;
  // This is to make sure banner works of internationalisation
  const title = ProductCategoryPage.title;
  const image = ProductCategoryPage?.paImage;
  const sanityImage = ProductCategoryPage?.paImage;
  const imageWidth = image?.asset?.metadata?.dimensions?.width;
  const imageHeight = image?.asset?.metadata?.dimensions?.height;
  const imageMimeType = image?.asset?.mimeType;
  const { bagItems, setBagItems } = AddToBag();
  const parentPagePath = `${ProductCategoryPage?.parentCategory?.name || ""}/${
    ProductCategoryPage?.parentCategory?.slug.current || ""
  }`;
  const currentPagePath = `${ProductCategoryPage.name}/${ProductCategoryPage.slug.current}`;

  const filteredProducts: any[] = [];
  props.pageContext.prefetchData.data?.allSanityProduct.nodes.map((product: any) => {
    product.productCategory.map((category: any) => {
      if (category.categoryId == props.data.categoryPage.categoryId) {
        filteredProducts.push(product);
      }
    });
  });

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data, bagItems, setBagItems }}
      pageName={pageTitle}
      pageType={"products"}
      analyticsTagMeta={{
        category: ProductCategoryPage?.parentCategory?.name,
        subCategory: ProductCategoryPage.name,
        promoId: "4"
      }}
    >
      <BreadCrumbs className="hidden" parentPagePath={parentPagePath} currentPagePath={currentPagePath} />
      <StructuredData
        type={"WebPage"}
        name={title}
        description={pageDescription}
        data={ProductCategoryPage.paImage}
        language={htmlLang}
        slug={`/${ProductCategoryPage?.heading}/`}
      />
      <SEO
        title={title}
        description={pageDescription}
        imageUrl={
          ProductCategoryPage?.paImage?.asset?.url ||
          "https://cdn.sanity.io/images/gsxasun1/sheamoisture-stg-us/b6973dfe40b2db0d60c9963b580f442e826a86d2-1080x1080.jpg"
        }
        imageAlt={ProductCategoryPage?.paImage?.alt}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        imageMimeType={imageMimeType}
        htmlLang={htmlLang}
      />
      <>
        {sanityImage && <HeroBanner title={title} sanityImage={sanityImage} isSmallBanner={true} />}
        <Container fluid data-testid="product-page">
          <ProductCategorySearch
            categoryPageId={props.data.categoryPage.categoryId}
            sanitySiteSettings={props.pageContext.sanitySiteSettings}
            productLabels={props.data?.sanityLabels?.productLabels}
            initData={filteredProducts}
          />
        </Container>
      </>
    </Layout>
  );
};

export default ProductCategoryPage;
